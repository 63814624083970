<template>
  <div id="maintheme">
    <div class="header">
      <div>
        <el-button
          v-if="showRemoveNewtheme"
          size="medium"
          type="primary"
          style="width: 100px"
          @click="
            $router.push({
              path: `/courseWorkbook/editor/assignment/${dataId}/new`,
            })
          "
        >
          布置作业
        </el-button>
      </div>
      <div class="flex-center">
        <div class="flex-center">
          <span class="title_name">查看方式</span>
          <el-select
            v-model="post1.privacy_type"
            class="ml10 mr20"
            style="width:150px"
            size="medium"
            placeholder="请选择"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <el-input
          @input="ismore = false"
          style="width: 318px"
          size="medium"
          v-model="post1.search"
          class="input-with-select"
          :placeholder="
            post1.type == 1 || post1.type == ''
              ? '输入作业名称搜索'
              : '输入老师名称搜索'
          "
        >
          <el-select
            @change="ismore = true"
            size="medium"
            v-model="post1.type"
            slot="prepend"
            placeholder="请选择"
          >
            <el-option label="作业" value="1"></el-option>
            <el-option label="点评老师" value="2"></el-option>
          </el-select>
        </el-input>
      </div>
    </div>
    <div class="mainTable">
      <pagination2
        @complete="complete"
        :option="post1"
        url="/Homework/themeList"
        ref="child"
      >
        <template v-slot:default="{ tableData }">
          <el-table
            :data="tableData"
            :header-cell-style="{
              background: 'rgba(245,245,245,1)',
              color: '#333333',
            }"
          >
            <el-table-column label="作业" prop="name">
              <template slot-scope="{ row }">
                <span :title="row.name" class="one_overflow">
                  {{ row.name }}
                </span>
              </template>
            </el-table-column>

            <el-table-column label="已提交/应提交" prop="sub_user_num">
              <template slot-scope="{ row }">
                {{ row.sub_user_num }}/{{ row.sc_count }}
              </template>
            </el-table-column>

            <el-table-column label="已点评/未点评" prop="comment_num">
              <template slot-scope="{ row }">
                {{ row.comment_num }}/{{ row.no_comment_num }}
              </template>
            </el-table-column>

            <el-table-column
              label="布置人"
              prop="uname"
              width="80px"
            ></el-table-column>

            <el-table-column label="布置时间">
              <template slot-scope="scope">
                {{ scope.row.c_time | formatTimeStamp('yyyy-MM-dd hh:mm') }}
              </template>
            </el-table-column>

            <el-table-column label="提交截止时间" width="290px">
              <template slot-scope="scope">
                {{
                  scope.row.start_submit_time
                    | formatTimeStamp('yyyy-MM-dd hh:mm')
                }}
                -
                {{
                  scope.row.end_submit_time
                    | formatTimeStamp('yyyy-MM-dd hh:mm')
                }}
              </template>
            </el-table-column>

            <el-table-column label="查看方式">
              <template slot-scope="scope">
                <span v-if="scope.row.privacy_type == 1">提交后可查看</span>
                <span v-else-if="scope.row.privacy_type == 2">
                  未提交可查看
                </span>
                <span v-else>不可查看</span>
              </template>
            </el-table-column>

            <el-table-column
              label="操作"
              min-width="120px"
              align="right"
              v-if="
                showRemoveCommenttheme ||
                  showRemoveEdittheme ||
                  showRemoveDeltheme
              "
            >
              <template slot-scope="{ row }">
                <el-button
                  v-if="showRemoveCommenttheme"
                  type="text"
                  @click="
                    $router.push({
                      path: `/courseWorkbook/studentWorkbook/${row.homework_theme_id}`,
                    })
                  "
                >
                  提交记录
                </el-button>
                <span class="span_1" v-if="showRemoveEdittheme">|</span>
                <el-button type="text" @click="share(row)">
                  分享
                </el-button>
                <span class="span_1" v-if="showRemoveEdittheme">|</span>
                <el-button
                  v-if="showRemoveEdittheme"
                  type="text"
                  @click="
                    $router.push({
                      path: `/courseWorkbook/editor/assignment/${dataId}/${row.homework_theme_id}`,
                    })
                  "
                >
                  编辑
                </el-button>
                <span class="span_1" v-if="showRemoveDeltheme">|</span>

                <el-button
                  type="text"
                  v-if="showRemoveDeltheme"
                  @click="delBook(row.homework_theme_id)"
                >
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </pagination2>
    </div>
    <!---分享作业--->
    <share
      v-if="share_url"
      :share_url="share_url"
      @close="share_url = ''"
    ></share>
  </div>
</template>
<script>
import share from '@cm/shareCodeAndUrl'

export default {
  props: ['studentcount'],

  components: {
    share,
  },

  data() {
    return {
      options: [
        {
          value: 0,
          label: '全部',
        },
        {
          value: 1,
          label: '提交后可查看',
        },
        {
          value: 2,
          label: '未提交可查看',
        },
        {
          value: 3,
          label: '不可查看',
        },
      ],
      dataId: this.$route.params.id,
      // 作业列表参数
      post1: {
        homework_id: this.$route.params.id,
        type: '1',
        search: '',
        page: 1,
        privacy_type: 0,
      },
      share_url: '',
    }
  },
  filters: {
    gettype(val) {
      let text
      switch (Number(val)) {
        case 1:
          text = '简答题'
          break
        case 2:
          text = '单选题'
          break
        default:
          text = '多选题'
          break
      }
      return text
    },
  },
  computed: {
    //新建主题
    showRemoveNewtheme() {
      return this.$store.state.roots.includes(132)
    },
    //编辑主题
    showRemoveEdittheme() {
      return this.$store.state.roots.includes(133)
    },
    //删除主题
    showRemoveDeltheme() {
      return this.$store.state.roots.includes(134)
    },
    //点评主题
    showRemoveCommenttheme() {
      return this.$store.state.roots.includes(135)
    },
  },
  methods: {
    //删除主题
    delBook(val) {
      this.$confirm(
        '删除后，学员已提交的内容也会同步删除，不可恢复！',
        '删除作业',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }
      ).then(() => {
        this.$http({
          url: '/Homework/delTheme',
          data: {
            homework_theme_id: val,
          },
          callback: ({ code }) => {
            if (code == 200) {
              this.$refs.child.reset()
              this.$root.prompt({
                msg: '操作成功',
                type: 'success',
              })
            }
          },
          error: () => {
            this.$root.prompt({
              msg: '操作失败',
            })
          },
        })
      })
    },
    // 分享题目
    share(item) {
      this.share_url = item.url
    },

    complete(val) {
      this.$emit('complete', val)
    },
  },
}
</script>
<style lang="scss" scoped>
#maintheme {
  .header {
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    ::v-deep .input-with-select {
      .el-input__inner {
        color: #333333;
      }
    }
    .title_name {
      font-size: 14px;
      color: #333333;
    }
  }

  .mainTable {
    margin: 20px;

    .span_1 {
      margin: 0 6px;
      color: #0aa29b;
    }

    .text {
      color: #0aa29b;
    }

    ::v-deep.el-dropdown-menu {
      .el-button--text {
        color: none;
      }

      width: 136px;

      p {
        width: 115px;
      }
    }
  }
}
</style>
