<template>
  <div id="studentManager">
    <div class="header">
      <div class="right">
        <el-input
          v-model="post.search"
          size="medium"
          style="width: 218px"
          placeholder="输入学员名称搜索"
        ></el-input>
      </div>
    </div>
    <div class="tablelist">
      <pagination2
        @complete="complete"
        :option="post"
        url="/Homework/studentList"
        ref="childFissionData"
      >
        <template v-slot:default="{ tableData }">
          <el-table
            :data="tableData"
            @sort-change="sortChange"
            max-height:200
            :header-cell-style="{
              background: 'rgba(245,245,245,1)',
              color: '#333333',
            }"
          >
            <el-table-column label="学员">
              <template slot-scope="{ row }">
                <div class="touxiang">
                  <div class="tu">
                    <img :src="row.uphoto" alt="" />
                  </div>
                  <div
                    class="name"
                    :title="
                      row.uname +
                        (row.student_remarks
                          ? '(' + row.student_remarks + ')'
                          : '')
                    "
                  >
                    {{ row.uname }}
                    <span v-if="row.student_remarks">
                      ({{ row.student_remarks }})
                    </span>
                  </div>
                </div>
              </template>
            </el-table-column>

            <el-table-column label="应提交" prop="sub_num"></el-table-column>

            <el-table-column label="已提交/未提交">
              <template slot-scope="scope">
                <p>
                  {{ scope.row.sub_user_num || 0 }}/{{
                    scope.row.not_sub_user_num || 0
                  }}
                </p>
              </template>
            </el-table-column>

            <el-table-column
              label="未点评"
              sortable="custom"
              prop="no_comment_num"
            >
              <template slot-scope="scope">
                <p class="redcolor">
                  {{ scope.row.no_comment_num }}
                </p>
              </template>
            </el-table-column>

            <el-table-column
              prop="all_score"
              label="累计得分"
              sortable="custom"
            >
              <template slot-scope="scope">
                <p>
                  {{ scope.row.all_score }}
                </p>
              </template>
            </el-table-column>

            <el-table-column label="操作" align="right">
              <template slot-scope="scope">
                <el-button type="text" @click="detail(scope.row)">
                  查看作业
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </pagination2>
    </div>

    <el-drawer title="我是标题" :visible.sync="drawer" direction="rtl">
      <div slot="title" class="drawertitle">查看作业</div>
      <div class="drawercontian">
        <ul>
          <li
            v-for="item in UserHomeWork"
            :key="item.homework_theme_id"
            @click="viewWork(item)"
          >
            <div class="name">{{ item.name }}</div>
            <div class="status">
              <span class="sp_1" v-if="item.is_sub">已提交</span>
              <span class="sp_2" v-else>未提交</span>
              <span class="sp_1 ml20" v-if="item.is_comment">已点评</span>
              <span class="ml20 sp_2" v-else>未点评</span>
            </div>
          </li>
        </ul>
      </div>
    </el-drawer>
  </div>
</template>
<script>
export default {
  name: 'studentManager',

  data() {
    return {
      UserHomeWork: [], //查看作业数据

      drawer: false, //抽屉开关

      //获取参与学员时提交的数据
      post: {
        homework_id: this.$route.params.id,
        search: '',
        sort: '',
        order: '',
      },
    }
  },

  computed: {
    //点评主题
    showRemoveCommenttheme() {
      return this.$store.state.roots.includes(135)
    },
  },
  methods: {
    //查看作业
    detail(val) {
      this.getUserHomeWork(val)
      this.drawer = true
    },

    // 跳到作业详情
    viewWork(item) {
      if(!item.is_sub) {
        this.$root.prompt('学员未提交作业')
        return
      }
      if (this.showRemoveCommenttheme) {
        this.$router.push({
          path: `/courseWorkbook/studentWorkbook/${item.homework_theme_id}/answer/${item.user_id}`,
        })
      }
    },

    //查看作业列表数据
    getUserHomeWork(val) {
      this.$http({
        url: 'Homework/getUserHomeWork',
        data: {
          homework_id: this.post.homework_id,
          user_id: val.user_id,
        },
        callback: ({ code, data }) => {
          if (code == 200) {
            this.UserHomeWork = data.map(item =>
              _.assign({}, item, { user_id: val.user_id })
            )
          }
        },
      })
    },

    //排序
    sortChange(val) {
      this.post.sort = !val.order ? '' : val.order === 'ascending' ? 1 : 2
      this.post.order = !val.order
        ? ''
        : val.prop === 'all_score'
        ? 1
        : val.prop === 'no_comment_num'
        ? 2
        : 3
    },

    //列表获取数据后整体数据
    complete({ count }) {
      this.$emit('complete', count)
    },
  },
}
</script>
<style lang="scss" scoped>
#studentManager {
  padding: 20px;
  box-sizing: border-box;
  ::v-deep .el-tabs__nav-scroll {
    padding: 0;
  }
  ::v-deep .el-drawer {
    width: 500px !important;
  }
  ::v-deep .el-tabs__header .el-tabs__item {
    width: 131px;
    text-align: center;
  }

  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background-color: #fff;
  }
  .drawercontian {
    overflow: auto;
    height: calc(100% - 38px);
    ul {
      li {
        cursor: pointer;
        padding: 14px 20px;
        border-bottom: 1px solid #f5f5f5;
        box-sizing: border-box;
        &:hover {
          background: #efefef;
        }
        .name {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
          overflow: hidden;
          font-size: 14px;
          color: #333333;
          line-height: 20px;
          margin-bottom: 10px;
        }
        .status {
          font-size: 12px;
          line-height: 12px;
          .sp_1 {
            color: #289027;
          }
          .sp_2 {
            color: #ff3535;
          }
        }
      }
    }
  }
  .drawertitle {
    font-size: 14px;
    font-weight: bold;
    color: #333333;
    line-height: 14px;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // margin-top: 30px;
    margin-bottom: 20px;
    justify-content: flex-end;
    .right {
      height: 40px;
      display: flex;
      align-items: center;
    }
  }
  .tablelist {
    .touxiang {
      display: flex;
      align-items: center;
      .tu {
        width: 34px;
        height: 34px;
        border-radius: 17px;
        margin-right: 10px;
        img {
          width: 34px;
          height: 34px;
        }
      }
      .name {
        width: calc(100% - 44px);
        font-size: 14px;
        color: #333333;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
</style>
