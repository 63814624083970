<template>
  <div class="editorDataAll">
    <div v-if="$route.name == 'editor'">
      <div class="headerMsg" v-cloak>
        <div class="workname" :title="headerMsg.name">
          作业本名称：{{ headerMsg.name }}
        </div>
        <div class="time_name">
          <div>
            创建时间：{{
              headerMsg.c_time | formatTimeStamp('yyyy-MM-dd hh:mm')
            }}
          </div>
          <div class="sp_1">创建者：{{ headerMsg.uname }}</div>
        </div>
      </div>
      <div class="footTabBox">
        <template>
          <el-tabs v-model="activeName">
            <el-tab-pane
              :label="'作业管理(' + mainthemelist + ')'"
              name="first"
            >
              <template>
                <div class="taskList" v-if="activeName == 'first'">
                  <maintheme
                    :studentcount="studentcount"
                    @complete="mainthemecomplete"
                  ></maintheme>
                </div>
              </template>
            </el-tab-pane>
            <el-tab-pane
              :label="'参与学员(' + studentcount + ')'"
              name="second"
            >
              <div v-show="activeName == 'second'">
                <student-manager @complete="complete"></student-manager>
              </div>
            </el-tab-pane>
          </el-tabs>
        </template>
      </div>

      <!-- 海报分享 -->
      <sharePop
        v-if="status2"
        :val="find"
        :dialogstatus.sync="status2"
      ></sharePop>

      <!-- <uploadfile
        v-if="uploadStart"
        @uploadConfirmClose="uploadConfirmClose"
        @completelist="completelist"
        @close="uploadConfirmClose"
        :typeselection="1"
        :title="'上传文件'"
      ></uploadfile> -->

      <el-dialog
        title="选择课程"
        width="850px"
        :visible.sync="dialogSelectVisible"
        :close-on-click-modal="false"
        :destroy-on-close="true"
      >
        <div slot="title">
          选择课程
          <span style="color: #ff3535; font-size: 13px">(多选)</span>
        </div>
        <dialog-selectcourse ref="dialogRef" :type="2"></dialog-selectcourse>
      </el-dialog>

      <el-dialog
        title="选择资料"
        width="850px"
        :visible.sync="dialogSelectVisible_data"
        :close-on-click-modal="false"
        :destroy-on-close="true"
      >
        <div slot="title">
          选择资料
          <span style="color: #ff3535; font-size: 13px">(多选)</span>
        </div>
        <dialog-selectdata ref="dialogRef_data" :type="2"></dialog-selectdata>
      </el-dialog>
    </div>
    <router-view></router-view>
  </div>
</template>
<script>
// import bg from '@/mixin/background'
import dialogSelectcourse from '@/components/base/dialogSelectcourse'
import dialogSelectdata from '@/components/base/dialogSelectData'
import sharePop from '@/views/courseWorkbook/components/sharePop'
// 学员管理
import studentManager from './studentManager'
// import uploadfile from '@cm/base/UploadtextFile'
//主题管理
import maintheme from './maintheme'
export default {
  components: {
    sharePop,
    // upload,
    // uploadfile,
    dialogSelectcourse,
    dialogSelectdata,
    studentManager,
    maintheme,
  },
  // mixins: [bg],
  data() {
    return {
      studentcount: '', //参与学员人数
      mainthemelist: '', //主题管理列表的长度
      orderlist_count: '', //订单数
      // completelist: [], //学员管理列表
      ismore: false, //手动加载
      dataId: '',
      // 作业列表数据总数
      count: '',
      //作业列表数据
      bookList: [],
      find: {},
      status2: false,
      value1: '1',
      value: 0,
      options2: [
        {
          value: 0,
          label: '全部状态',
        },
        {
          value: 1,
          label: '未支付',
        },
        {
          value: 2,
          label: '支付成功',
        },
      ],
      value3: '1', //搜索类型
      savesecondList: [],
      headerMsg: {},
      dialogSelectVisible_data: false, //控制选择资料
      // course: [], //选择的课程
      dialogSelectVisible: false, //控制选择课程
      sum: '', //给每一个上传的文档给一个index用来排序
      sum_1: '', //给每一个添加的课程给一个index用来排序
      uploadStart: false, //上传弹窗控制
      activeName: 'first',
    }
  },
  created() {
    this.getHeader()
  },
  watch: {
    $route: {
      handler(to) {
        this.dataId = this.$route.params.id
        if (to.name === 'editor') {
          // console.log(to.name)
          this.getHeader()
        }
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {},
  methods: {
    //主提管理的列表
    mainthemecomplete(val) {
      this.mainthemelist = val.count
    },
    complete2({ count }) {
      this.orderlist_count = count
    },
    sortChange(val) {
      this.post2.sort = !val.order ? '' : val.order === 'ascending' ? 1 : 2
      this.post2.order = !val.order ? '' : val.prop === 'actual_price' ? 1 : 2
    },
    //学员管理列表回调
    complete(val) {
      this.studentcount = val
    },

    getShareInfo(val) {
      this.find = val
      this.status2 = true
    },
    //点击编辑跳转
    bianji(val) {
      this.$router.push({ path: `/courseWorkbook/newData/${val}` })
    },
    //获取头部的信息
    getHeader() {
      this.dataId = this.$route.params.id
      this.$http({
        url: '/Homework/getHeader',
        data: {
          homework_id: this.dataId,
        },
        callback: data => {
          this.headerMsg = Object.assign({}, data.data)
        },
      })
    },
  },
  filters: {
    getFileSize(value) {
      var size = value / 1024 / 1024 / 1024

      function getPointNum(num, n) {
        let str = String(num)
        let index = str.indexOf('.')
        let str1 = str.substring(0, index + n + 1)
        str1 = Number(str1)
        return str1
      }
      size = getPointNum(size, 3) * 1024

      return size > 0
        ? size.toFixed(2) + 'MB'
        : (0.001 * 1024).toFixed(2) + 'MB'
    },
  },
}
</script>
<style lang="less" scoped>
::v-deep .el-input-group__prepend {
  background-color: #fff;
  width: 72px !important;
}

.disabled {
  color: #c0c4cc !important;
  cursor: not-allowed !important;
  opacity: 0.6;
}

.upload-text {
  font-size: 16px;
  line-height: 42px;
  margin: 0 15px;
  cursor: pointer;

  color: #0aa29b;
}

.tianjia {
  position: absolute;
  height: 42px;
  width: 90px;
  // background-color: red;
  top: 0px;
  left: 145px;
  cursor: pointer;
  opacity: 0.3;
}

.editorDataAll {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.footTabBox {
  min-width: 942px;
  padding: 20px 0;
  border-top: 20px solid rgb(245, 245, 245);
  padding-bottom: 30px;
  background-color: #fff;
}

.headerMsg {
  width: 100%;
  background-color: #fff;
  height: 85px;
  padding: 20px;
  min-width: 942px;
  box-sizing: border-box;
  font-size: 12px;
  color: #333333;
  line-height: 12px;
  .workname {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .time_name {
    margin-top: 20px;
    display: flex;
    .sp_1 {
      margin-left: 71px;
    }
  }
}
</style>
