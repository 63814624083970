<template>
  <el-dialog
    width="800px"
    title="分享"
    :visible="true"
    @close="$emit('close')"
  >
    <div class="shareCodeAndUrl">
      <div class="containEwm">
        <div class="zbEwm">
          <div class="tu" id="qrcode"></div>
          <el-button type="text" class="download" @click="downEwm">
            下载二维码
          </el-button>
        </div>

        <div class="zbEwm">
          <div class="linkcontain">
            {{ share_url }}
          </div>
          <el-button
            type="text"
            style="margin-right: 48px"
            class="for-copy"
            :data-clipboard-text="share_url"
          >
            复制链接
          </el-button>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import ClipboardJS from 'clipboard'
import QRCode from '@/assets/js/qrcode'
export default {
  name: 'shareCodeAndUrl',
  data() {
    return {}
  },

  props: {
    share_url: String,
  },

  mounted() {
    this.$nextTick(() => {
      this.create_ewm()
      this.initCopy()
    })
  },

  methods: {
    //复制链接
    initCopy() {
      const self = this
      let clipboard = new ClipboardJS('.for-copy')
      clipboard.on('success', function() {
        self.$root.prompt({
          msg: '复制成功',
          type: 'success',
        })
      })
      clipboard.on('error', function() {
        self.$root.prompt('复制失败')
      })
    },

    // 生成二维码
    create_ewm() {
      const size = 155
      //生成前先清空原有的，不然会有多张二维码的情况
      document.querySelector('#qrcode').innerHTML = ''
      const node = document.getElementById('qrcode')
      if (node) {
        this.qrcode = new QRCode(node, {
          text: this.share_url, //地址
          width: size,
          height: size,
          colorDark: '#000000',
          colorLight: '#ffffff',
          correctLevel: QRCode.CorrectLevel.H,
        })
      }
    },

    // 下载二维码
    downEwm() {
      const imgUrl = this.qrcode.getBase64Code()
      if (window.navigator.msSaveOrOpenBlob) {
        var bstr = atob(imgUrl.split(',')[1])
        var n = bstr.length
        var u8arr = new Uint8Array(n)
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n)
        }
        var blob = new Blob([u8arr])
        window.navigator.msSaveOrOpenBlob(blob, 'chart-download' + '.' + 'png')
      } else {
        // 这里就按照chrome等新版浏览器来处理
        const a = document.createElement('a')
        a.href = imgUrl
        a.setAttribute('download', 'chart-download')
        a.click()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.shareCodeAndUrl {
  font-size: 14px;
  color: #666666;
  line-height: 22px;

  img {
    height: 100%;
    width: 100%;
  }

  .containEwm {
    display: flex;
    justify-content: center;

    .zbEwm {
      display: flex;
      flex-direction: column;

      .tu {
        margin: 0 0 10px 0;
        width: 155px;
        height: 155px;
      }

      .download {
        margin: 0 auto;
      }
    }
  }
  .linkcontain {
    margin: 0 auto;
    font-size: 15px;
    color: #666666;
    line-height: 20px;
    padding: 20px;
    width: 370px;
    height: 155px;
    background: #f5f5f5;
    border-radius: 4px;
    border: 1px solid #d3d3d3;
    margin-left: 40px;
    box-sizing: border-box;
    margin-bottom: 8px;
  }
}
</style>
